import "@gomdigital/sheep-two/dist/styles/App.scss";
import "./App.css";
import {
  Header,
  Hero,
  Half,
  Columns,
  Section,
  CardItems,
  Footer,
} from "@gomdigital/sheep-two";
function App() {
  return (
    <div className="main">
      <div className="relative">
        {/* Header */}
        <Header
          btnText=""
          btnHref=""
          logo={<img alt="logo" src={"ai-logic-logo.svg"} className="w-[300px] h-[50px]" />}
          absolute={true}
          modal={false}
        />

        {/* Hero */}
        <Hero
          title="Simplifying the Complexities of Global Trade"
          desc={
            <>
              AI technology specializing in the financial sector covering a wide range of applications
            </>
          }
          primaryBtnText=""
          primaryBtnHref=""
          secondaryBtnText=""
          secondaryBtnHref=""
          heroStyle="bg-cover bg-no-repeat"
        />
      </div>

      {/* Half */}
      <Section title="Our Key AI Tech">
        <Half
          title=""
          image={
            <img
              alt="AI"
              src={"content1.png"}
              className="inset-0 w-[90%] h-[90%]"
            />
          }
          content={
            <>
              <span className="text-2xl font-bold mb-4 block text-left">Machine Learning Frameworks</span>
                <li><b>TensorFlow:</b> Google’s open-source machine learning platform.</li>
                <li><b>Scikit-learn:</b> Python library for predictive data analysis.</li>
              <br/>
              <span className="text-2xl font-bold mb-4 block text-left">Big Data Platforms</span>
                <li><b>Apache Hadoop:</b><span>Framework for distributed data storage and processing.</span></li>
                <li><b>Apache Spark:</b> Unified analytics engine for big data processing.</li>
                <li><b>Apache Kafka:</b> Event streaming platform for handling large data volumes.</li>
              <br />
              <span className="text-2xl font-bold mb-4 block text-left">Data Storage and Management</span>
                <li><b>NoSQL Databases:</b> MongoDB, Cassandra, Redis for large unstructured data.</li>
                <li><b>Data Lakes:</b> Amazon S3, Azure Data Lake for storing vast raw data.</li>
            </>
          }
          imageStyle={"bg-[#E2E5EC] w-100 lg:h-[467px] rounded-[20px]"}
          orderLast={false}
          equalCols={true}
        />
      </Section>

      {/* Half */}
      <Section title="">
        <Half
          title=""
          image={
            <img
              alt="AI"
              src={"content2.png"}
              className="inset-0 w-[90%] h-[90%]"
            />
          }
          content={
            <>
              <span className="text-2xl font-bold mb-4 block text-left">Cloud Services</span>
                <li><b>AWS Fraud Detector:</b> Identifies potential online fraud.</li>
                <li><b>Google Cloud AI Platform:</b> Manages machine learning models.</li>
                <li><b>Azure Machine Learning:</b> Develops and deploys ML models.</li>
              <br/>
              <span className="text-2xl font-bold mb-4 block text-left">Cybersecurity Tools</span>
                <li><b>SIEM Systems:</b> Splunk, IBM QRadar for security monitoring.</li>
                <li><b>Endpoint Detection and Response (EDR):</b> Detects and responds to endpoint threats.</li>
              <br />
              <span className="text-2xl font-bold mb-4 block text-left">Anomaly Detection Algorithms</span>
                <li><b>Isolation Forest:</b> Identifies anomalies in high-dimensional data.</li>
                <li><b>Autoencoders:</b> Neural networks for unsupervised anomaly detection.</li>
                <li><b>Support Vector Machines (SVM):</b> For outlier classification and detection.</li>
            </>
          }
          imageStyle={"bg-[#E2E5EC] w-100 lg:h-[467px] rounded-[20px]"}
          orderLast={true}
          equalCols={true}
        />
      </Section>


      {/* 6 col */}
      <Section title="What We Do For You">
        <Columns col={3}>
          <CardItems
            obj={[
              {
                icon: 
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_36_3075" Style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                <rect width="48" height="48" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_36_3075)">
                <path d="M24 33.95C24.4667 33.95 24.875 33.775 25.225 33.425C25.575 33.075 25.75 32.6667 25.75 32.2C25.75 31.7333 25.575 31.325 25.225 30.975C24.875 30.625 24.4667 30.45 24 30.45C23.5333 30.45 23.125 30.625 22.775 30.975C22.425 31.325 22.25 31.7333 22.25 32.2C22.25 32.6667 22.425 33.075 22.775 33.425C23.125 33.775 23.5333 33.95 24 33.95ZM22.5 26.75H25.5V13.6H22.5V26.75ZM16.5 42L6 31.5V16.5L16.5 6H31.5L42 16.5V31.5L31.5 42H16.5ZM17.75 39H30.25L39 30.25V17.75L30.25 9H17.75L9 17.75V30.25L17.75 39Z" fill="#1C002C"/>
                </g>
                </svg>,
                card_title: "Fraud Detection and Prevention",
                card_content:
                  "Monitors and identifies suspicious transactions using ML models and big data analytics.",
              },
              {
                icon: 
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_36_3082" Style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                <rect width="48" height="48" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_36_3082)">
                <path d="M6 40V14L14 20L24 6L34 14H42V40H6ZM16.2 35.45L24.2 24.4L39 35.95V17H32.95L24.6 10.3L14.7 24.25L9 20V29.7L16.2 35.45Z" fill="#1C002C"/>
                </g>
                </svg>,
                card_title: "Algorithmic Trading",
                card_content:
                  "Develops high-frequency trading strategies and predictive modelling.",
              },
              {
                icon: 
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_36_3087" Style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                <rect width="48" height="48" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_36_3087)">
                <path d="M2 42L24 4L46 42H2ZM7.2 39H40.8L24 10L7.2 39ZM24.209 36.15C24.6363 36.15 24.9917 36.0053 25.275 35.716C25.5583 35.427 25.7 35.0687 25.7 34.641C25.7 34.2137 25.5553 33.8583 25.266 33.575C24.977 33.2917 24.6187 33.15 24.191 33.15C23.7637 33.15 23.4083 33.2947 23.125 33.584C22.8417 33.873 22.7 34.2313 22.7 34.659C22.7 35.0863 22.8447 35.4417 23.134 35.725C23.423 36.0083 23.7813 36.15 24.209 36.15ZM22.7 30.6H25.7V19.4H22.7V30.6Z" fill="#1C002C"/>
                </g>
                </svg>
                ,
                card_title: "Risk Management",
                card_content:
                  "Assesses credit risk and predicts financial risks.",
              },
              {
                icon: 
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_36_3094" Style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                <rect width="48" height="48" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_36_3094)">
                <path d="M12 28.05H27.65V25.05H12V28.05ZM12 21.55H36V18.55H12V21.55ZM12 15.05H36V12.05H12V15.05ZM4 44V7C4 6.2 4.3 5.5 4.9 4.9C5.5 4.3 6.2 4 7 4H41C41.8 4 42.5 4.3 43.1 4.9C43.7 5.5 44 6.2 44 7V33C44 33.8 43.7 34.5 43.1 35.1C42.5 35.7 41.8 36 41 36H12L4 44ZM10.7 33H41V7H7V37L10.7 33Z" fill="#1C002C"/>
                </g>
                </svg>
                ,
                card_title: "Customer Service",
                card_content:
                  "Implements chatbots and personalised customer experiences.",
              },
              {
                icon: 
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_36_3142" Style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                <rect width="48" height="48" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_36_3142)">
                <path d="M22.6 43.8501C17.3333 43.4501 12.925 41.3584 9.375 37.5751C5.825 33.7918 4.05 29.2668 4.05 24.0001C4.05 18.7334 5.825 14.2084 9.375 10.4251C12.925 6.64176 17.3333 4.5501 22.6 4.1501V9.2501C18.7667 9.61676 15.5583 11.2084 12.975 14.0251C10.3917 16.8418 9.11667 20.1668 9.15 24.0001C9.18333 27.8334 10.475 31.1584 13.025 33.9751C15.575 36.7918 18.7667 38.3834 22.6 38.7501V43.8501ZM25.6 43.8501V38.7501C29.1333 38.4501 32.1167 37.0501 34.55 34.5501C36.9833 32.0501 38.4167 29.0334 38.85 25.5001H43.95C43.5833 30.4668 41.675 34.6834 38.225 38.1501C34.775 41.6168 30.5667 43.5168 25.6 43.8501ZM38.85 22.5001C38.45 18.9668 37.025 15.9501 34.575 13.4501C32.125 10.9501 29.1333 9.53343 25.6 9.2001V4.1001C30.5333 4.46676 34.7333 6.38343 38.2 9.8501C41.6667 13.3168 43.5833 17.5334 43.95 22.5001H38.85Z" fill="#1C1B1F"/>
                </g>
                </svg>
                ,
                card_title: "Wealth Management",
                card_content:
                  "Provides automated investment advice and portfolio management.",
              },
              {
                icon: 
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_36_3105" Style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                <rect width="48" height="48" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_36_3105)">
                <path d="M21.8 30.65L33.2 19.25L31.1 17.2L21.95 26.35L16.9 21.3L14.7 23.5L21.8 30.65ZM24 43.95C19.3333 42.7833 15.5 40.075 12.5 35.825C9.5 31.575 8 26.9166 8 21.85V9.94995L24 3.94995L40 9.94995V21.85C40 26.9166 38.5 31.575 35.5 35.825C32.5 40.075 28.6667 42.7833 24 43.95ZM24 40.85C27.8333 39.5833 30.9583 37.1916 33.375 33.675C35.7917 30.1583 37 26.2166 37 21.85V12.05L24 7.14995L11 12.05V21.85C11 26.2166 12.2083 30.1583 14.625 33.675C17.0417 37.1916 20.1667 39.5833 24 40.85Z" fill="#1C002C"/>
                </g>
                </svg>
                ,
                card_title: "Regulatory Compliance",
                card_content:
                  "Automates compliance checks and monitors transactions.",
              },
              {
                icon: 
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_36_3112" Style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                <rect width="48" height="48" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_36_3112)">
                <path d="M7.00001 43.75L4.70001 41.4501L19.7 26.45L27.7 34.5L42.6 17.75L44.7 19.8L27.7 39L19.7 31.05L7.00001 43.75ZM7.00001 30.3L4.70001 28L19.7 13L27.7 21.05L42.6 4.30005L44.7 6.35005L27.7 25.55L19.7 17.6L7.00001 30.3Z" fill="#1C002C"/>
                </g>
                </svg>
                ,
                card_title: "Predictive Analytics",
                card_content:
                  "Forecasts market trends and enhances decision-making.",
              },
              {
                icon: 
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_36_3119" Style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                <rect width="48" height="48" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_36_3119)">
                <path d="M8 40V16.5H15V40H8ZM8 15V8H15V15H8ZM20.5 40V22.5H27.5V40H20.5ZM20.5 21V14H27.5V21H20.5ZM33 40V28.5H40V40H33ZM33 27V20H40V27H33Z" fill="#1C002C"/>
                </g>
                </svg>
                ,
                card_title: "Personalised Financial Products",
                card_content:
                  "Creates tailored products to enhance engagement.",
              },
              {
                icon: 
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_36_3139" Style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                <rect width="48" height="48" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_36_3139)">
                <path d="M30.2 42V35.75H22.5V15.25H17.85V21.75H4V6H17.85V12.25H30.2V6H44V21.75H30.2V15.25H25.5V32.75H30.2V26.25H44V42H30.2ZM33.2 18.75H41V9H33.2V18.75ZM33.2 39H41V29.25H33.2V39ZM7 18.75H14.85V9H7V18.75Z" fill="#1C1B1F"/>
                </g>
                </svg>
                ,
                card_title: "Operational Efficiency",
                card_content:
                  "Streamlines processes and reduces operational costs.",
              },
            ]}
            bg=""
          />
        </Columns>
      </Section>

      {/* Footer */}
      <Footer
        logo={
          <img alt="logo" src={"ai-logic-logo.svg"} className="w-[250px] h-[40px]" />
        }
        ftrInfo={[
          {
           
          },
          {
          },
        ]}
        ftrInfo2={[
          {
            content: [
             
            ],
          },
        ]}
        textBottom={
          <>
            All contents @ 2024 ai logic. All rights reserved.
          </>
        }
      />
    </div>
  );
}

export default App;
